<template>
  <v-container>
    <validation-observer ref="observer">
      <form class="p-2" @submit.prevent="submit">
        <validation-provider v-slot="{ errors }" name="Name" rules="required">
          <v-text-field v-model="permissions.name" :error-messages="errors" label="Name" required outlined>
          </v-text-field>
        </validation-provider>

        <validation-provider v-slot="{ errors }" name="Guard Name" rules="required">
          <v-text-field v-model="permissions.guard_name" :error-messages="errors" label="Guard Name" required outlined>
          </v-text-field>
        </validation-provider>

        <v-btn type="submit" color="primary" class="ma-10 pa-5">
          Save
        </v-btn>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import util from "@/core/utils/misc";
import Request from "@/core/api/request";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "PermissionAdd",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return { permissions: {} };
  },
  methods: {
    async submit() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait while we process." });
        await Request.post(`${this.$IYA.URI.PERMISSIONS__ADD}`, this.permissions).then(response => {
          if (response.status === this.$IYA.HTTP.OK) {
            this.$swal(`Done !`, `Action accomplished`, `success`);
          }
        });
        util.isLoading();
      }
    },
  },
};
</script>
